import { Link as RouterLink } from "react-router-dom";
import {
    Box,
    Chip,
    Container,
    Link,
    Tooltip,
    Typography,
} from "@material-ui/core";

const platformIcons = {
    Amplify: "/static/icons/amplify.svg",
    Auth0: "/static/icons/auth0.svg",
    Firebase: "/static/icons/firebase.svg",
    JWT: "/static/icons/jwt.svg",
};

const AuthBanner = () => (
    <Box
        sx={{
            backgroundColor: "background.paper",
            borderBottom: 1,
            borderColor: "divider",
            py: 2,
        }}
    >
        <Container maxWidth="md">
            <Box
                sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Chip color="primary" label="Mitel Network Test" size="small" />
            </Box>
        </Container>
    </Box>
);

export default AuthBanner;
