import { postProtected } from "./baseCalls";

const getAccounts = async (orderBy, direction, offset, limit) => {
    console.log(orderBy, direction, offset, limit);
    return await postProtected("/admin/crud/accounts/list", {
        orderBy,
        direction,
        offset,
        limit,
    });
};

const getAccountDetails = async (id) => {
    return await postProtected("/admin/crud/accounts/details", { id: id });
};

const getOrders = async (orderBy, direction, offset, limit) => {
    console.log(orderBy, direction, offset, limit);
    return await postProtected("/admin/crud/orders/list", {
        orderBy,
        direction,
        offset,
        limit,
    });
};

const getOrderDetails = async (id) => {
    return await postProtected("/admin/crud/orders/details", { id: id });
};

const addOrder = async (oppId) => {
    return await postProtected("/admin/crud/orders/add", {
        oppId,
    });
};

const getTests = async (orderBy, direction, offset, limit) => {
    console.log(orderBy, direction, offset, limit);
    return await postProtected("/admin/crud/tests/list", {
        orderBy,
        direction,
        offset,
        limit,
    });
};

const getTestDetails = async (id) => {
    return await postProtected("/admin/crud/tests/details", { id: id });
};

export {
    getAccounts,
    getAccountDetails,
    getOrders,
    getOrderDetails,
    addOrder,
    getTests,
    getTestDetails,
};
