export const THEMES = {
    LIGHT: "LIGHT",
    DARK: "DARK",
    NATURE: "NATURE",
};

export const TESTSTATUS = {
    CREATED: "CREATED",
    INPROGRESS: "INPROGRESS",
    STOPPED: "STOPPED",
    COMPLETED: "COMPLETED",
};
