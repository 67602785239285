import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { authApi } from "../__fakeApi__/authApi";
import { login as apiLogin, logout as apiLogout } from "../apiCalls";
import jwt_decode from "jwt-decode";

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
};

const handlers = {
    INITIALIZE: (state, action) => {
        const { isAuthenticated, user } = action.payload;

        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user,
        };
    },
    LOGIN: (state, action) => {
        const { user } = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            user,
        };
    },
    LOGOUT: (state) => ({
        ...state,
        isAuthenticated: false,
        user: null,
    }),
    REGISTER: (state, action) => {
        const { user } = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            user,
        };
    },
};

const reducer = (state, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
    ...initialState,
    platform: "JWT",
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    register: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
    const { children } = props;
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const initialize = async () => {
            try {
                const accessToken = window.localStorage.getItem("accessToken");

                if (accessToken) {
                    const user = jwt_decode(accessToken);

                    dispatch({
                        type: "INITIALIZE",
                        payload: {
                            isAuthenticated: true,
                            user,
                        },
                    });
                } else {
                    dispatch({
                        type: "INITIALIZE",
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: "INITIALIZE",
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                });
            }
        };

        initialize();
    }, []);

    const login = async (emailAddress, password) => {
        const response = await apiLogin(emailAddress, password);
        const { accessToken, refreshToken } = response.data;
        const user = jwt_decode(accessToken);
        console.log(user);
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);

        dispatch({
            type: "LOGIN",
            payload: {
                user,
            },
        });
    };

    const logout = async () => {
        try {
            const refreshToken = window.localStorage.getItem("refreshToken");
            await apiLogout(refreshToken);
        } catch (error) {
            console.log(error);
        }
        console.log("Removing accesstoken and refreshtoken");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");

        dispatch({ type: "LOGOUT" });
    };

    const register = async (email, name, password) => {
        const accessToken = await authApi.register({ email, name, password });
        const user = jwt_decode(accessToken);

        localStorage.setItem("accessToken", accessToken);

        dispatch({
            type: "REGISTER",
            payload: {
                user,
            },
        });
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                platform: "JWT",
                login,
                logout,
                register,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AuthContext;
