import { postProtected } from "./baseCalls";

const getSfUsageSummary = async () => {
    return await postProtected("/admin/analytics/sfusagesummary");
};

const getVwUsageSummary = async () => {
    return await postProtected("/admin/analytics/vwUsageSummary");
};

const getLatestTests = async () => {
    return await postProtected("/admin/analytics/latestTests");
};

const analytics = { getSfUsageSummary, getVwUsageSummary, getLatestTests };

export default analytics;
