let config = null;
const prod = {
    API_URL: "https://na-prod-voipready-01.ue.r.appspot.com",
};
const qa = {
    API_URL: "https://na-sandbox-voipready-01.ue.r.appspot.com",
};
const dev = {
    API_URL: "http://localhost:8080",
};
const qa_cloudRun = {
    API_URL: "https://still-api-bkb7moo3za-uc.a.run.app",
};
switch (process.env.NODE_ENV) {
    case "Developent":
    case "development":
        config = dev;
        break;
    case "qa":
    case "QA":
    case "alpha":
        config = qa;
        break;
    case "production":
    case "Production":
    case "prod":
        config = prod;
        break;
    default:
        config = qa;
        break;
}

export default qa_cloudRun;
