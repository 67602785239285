import { postProtected } from "./baseCalls";

const getAllTests = async (startDatetime, endDatetime, status) => {
    console.log("In function");
    return await postProtected("/admin/reports/alltestsbydate", {
        startDatetime,
        endDatetime,
        status,
    });
};

const getCompletedTests = async (startDatetime, endDatetime, result) => {
    console.log("In function");
    return await postProtected("/admin/reports/completedtestsbydate", {
        startDatetime,
        endDatetime,
        result,
    });
};

const reports = { getAllTests, getCompletedTests };

export default reports;
