import createError from "http-errors";
import axios from "axios";
import config from "../environments";
import { refreshLogin } from "./authentication";

// const apiUrl = "http://localhost:8080";
const apiUrl = config.API_URL;

const get = async (uri, data) => {
    try {
        const url = new URL(uri, apiUrl);
        for (const key in data) url.searchParams.append(key, data[key]);
        const response = await axios.get(url.href);
        return response;
    } catch (error) {
        if (error.response) {
            throw createError(
                error?.response?.status || 400,
                `${error?.response?.data?.error?.message}`
            );
        } else {
            // Something happened in setting up the request that triggered an Error
            throw createError(408, "Request Timedout");
        }
    }
};

const post = async (uri, data) => {
    try {
        const url = new URL(uri, apiUrl);
        const response = await axios.post(url.href, data);
        return response;
    } catch (error) {
        if (error.response) {
            // Request made and server responded
            throw createError(
                error?.response?.status || 400,
                `${error?.response?.data?.error?.message}`
            );
        } else {
            // Something happened in setting up the request that triggered an Error
            throw createError(408, "Request Timedout");
        }
    }
};

const axiosDelete = async (uri, data) => {
    try {
        const url = new URL(uri, apiUrl);
        return await axios.delete(url.href, { data });
    } catch (error) {}
};

const postProtected = async (uri, data) => {
    try {
        const accessToken = window.localStorage.getItem("accessToken");
        const config = {
            headers: { authorization: `Bearer ${accessToken}` },
        };
        const url = new URL(uri, apiUrl);
        try {
            const response = await axios.post(url.href, data, config);
            return response;
        } catch (error) {
            const existingRefreshToken =
                window.localStorage.getItem("refreshToken");
            const tokenUrl = new URL("/admin/auth/refreshlogin", apiUrl);
            const tokens = await axios.post(tokenUrl.href, {
                refreshToken: existingRefreshToken,
            });
            const { accessToken, refreshToken } = tokens.data;
            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("refreshToken", refreshToken);

            const config = {
                headers: { authorization: `Bearer ${accessToken}` },
            };
            const response = await axios.post(url.href, data, config);
            return response;
        }
    } catch (error) {
        console.log(error);
    }
};

export { get, post, axiosDelete, postProtected, apiUrl };
