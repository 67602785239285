import { get, post, apiUrl, axiosDelete } from "./baseCalls";

const confirmOrderUid = async (uid) => {
    return await get("/order/verify", {
        uid,
    });
};

const submitAddTestForm = async (formData) => {
    return await post("/test/addtest", formData);
};

const getTestInfo = async (accessToken) => {
    return await post("/test/status", { accessToken });
};

const downloadExecutable = (accesstoken) => {
    if (accesstoken) {
        const url = new URL("/test/download", apiUrl);
        url.searchParams.append("accesstoken", accesstoken);
        console.log(url.href);
        window.open(url.href);
    }
};

const viewReport = (sid) => {
    window.open(
        `http://voipready.mitelcloud.com/myspeed/userpub/MINT/${sid}.html`
    );
};

const getOrderStatus = async (accessToken) => {
    return await get("/order/status", {
        accessToken,
    });
};

const addReportRecipient = async (formData) => {
    return await post("/test/addreportrecipient", formData);
};

const deleteReportRecipient = async (accessToken, emailAddress) => {
    return await axiosDelete("/test/deletereportrecipient", {
        accessToken,
        emailAddress,
    });
};

const appApiCalls = {
    confirmOrderUid,
    submitAddTestForm,
    getTestInfo,
    downloadExecutable,
    viewReport,
    getOrderStatus,
    deleteReportRecipient,
    addReportRecipient,
};
export default appApiCalls;
