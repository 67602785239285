import { post } from "./baseCalls";

const login = async (emailAddress, password) => {
    return await post("/admin/auth/login", {
        emailAddress,
        password,
    });
};

const refreshLogin = async (refreshToken) => {
    return await post("/admin/auth/refreshlogin", {
        refreshToken,
    });
};

const logout = async (refreshToken) => {
    return await post("/admin/auth/logout", {
        refreshToken,
    });
};

export { login, refreshLogin, logout };
